:root {
  --color-grijswit: #f6f8fa;
  --color-lichtblauw: #c7e0ed;
  --color-zwart: #002234;
  --color-donkerblauw: #004f74;
  --color-lichtgrijs: #afc3cd;
  --color-grijs: #58595b;
  --color-grijsblauw: #587684;
  --color-felblauw: #0171ac;
  --color-rood: #cc0056;
  --color-paarsblauw: #0021cc;
  --color-wit: #fff;
  --color-bordergrijs: #e3e9ec;
  --color-groen: #04ac01;
  --color-oranje: #c50;
  --color-donkeroranje: #be5c23;
  --font-family-body: 'Roboto', sans-serif;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --gender-background-male: #e6f1f7;
  --drivers-licence-background: #e6f1f7;
  --gender-background-female: #fae5ee;
  --line-height-m: 1.5;
  --font-size-xxxl: 8.1rem;
  --font-size-xxl: 5.4rem;
  --font-size-xl: 3.6rem;
  --font-size-l: 2.4rem;
  --font-size-m: 1.6rem;
  --font-size-s: 1.1rem;
  --font-size-xs: 0.7rem;
  --container-s: 53.4rem;
  --container-m: 63.4rem;
  --container-l: 124.8rem;
  --border-radius: 0.4rem;
  --border-radius-large: 0.8rem;
}

::selection {
  background: var(--color-zwart);
  color: var(--color-grijswit);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%; /* 1rem = 10px */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-grijswit);
  color: var(--color-zwart);
  font-family: var(--font-family-body);
  font-size: var(--font-size-m);
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: var(--font-family-body);
}

h1,
h2,
h3 {
  font-weight: var(--font-weight-regular);
}
